.profile-container {
    margin-inline-start: 60px;
    /* margin-top: 10px; */
}

.section-container {
    display: flex;
    flex-direction: column;
}

.profile-container h1 {
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 45px;
}

.profile-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: -30px; */
    margin-right: 30px;
}
.item-scroll {
    justify-content: center;
    margin-right: 5px;
}

.create-album-button {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-left: 15px;
    color: #21cc06;
    font-size: 21px;
}

.create-album-button:hover {
    color: rgb(34, 255, 0);
}

/* .section-container h2{
    margin-bottom: -13px;
} */

.album-grid {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.song-grid {
    display: flex;
    flex-direction: row;
}

.profile-tile-buttons {
    display: flex;
    position: absolute;
    right: 5px;
    top: 5px;
    opacity: 0;
    transform: translateY(20px);
    transition: visibility 0s, opacity 0.3s, transform 0.3s;
    z-index: 98;
}

.profile-tile-container:hover .profile-tile-buttons {
    opacity: 1;
    transform: translateY(0);
}

.profile-tile-buttons > * + * {
    margin-left: 4px;
    color:rgb(208, 0, 0);
}

/* .update-delte-music-buttons fa-solid {
    position: relative;
    right: 34px;
} */

.profile-tile-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
    gap: 25px;
}

.create-playlist-button {
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-left: 15px;
    color: #21cc06;
    font-size: 21px;
}

.create-playlist-button:hover {
    color: rgb(34, 255, 0);
}

.profile-container .item-scroll {
    display: flex;
    align-items: flex-end;
}

.profile-container .inner-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.item-scroll .fa-solid.disabled {
    color: #444444;
  }
