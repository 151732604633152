.like-button {
    background-color: transparent;
    color: rgb(160, 160, 160);
    box-shadow: 0px;
    border: 0px;
}

.fa-heart.fa-regular.fa-heart:hover {
    color: white;
}

#filled-like-heart {
    color: rgb(251, 139, 139);
}
