.album-details-container {
    padding-left: 3%;
}

.album-header-container {
    display: flex;
    margin-bottom: 2%;
}

.album-info-container {
    width: 100%;
    align-self: bottom;
    padding-left: 3%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.album-details-art {
    width: 270px;
    height: 270px;
    object-fit: cover;
}

#album-info {
    margin-bottom: 2.5px;
}

#album-length {
    margin-top: 2.5px;
    color: rgba(255, 255, 255, 0.701)
}

.album-name-header {
    font-size: 400%;
    margin-top: 0%;
    margin-bottom: 0%;
}

#album-clock-icon {
    margin-right: 2.65%;
}

.album-songs-container {
    padding-left: 0%;
    margin-top: 1%;
}

.album-songs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    border-bottom: .5px solid rgb(74, 74, 74);
    margin-bottom: 25px;
}

.fa-regular.fa-clock {
    color: rgb(160, 160, 160);
    padding-right: 5%;
}

.album-song-time {
    color: rgb(160, 160, 160);

}

.albums-songs-button {
    background-color: transparent;
    color: rgb(160, 160, 160);
    border: 0px;
    box-shadow: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 5%;
    margin-top: 17px;
}

.albums-songs-button:hover {
    background-color: rgb(45, 45, 45);
    border-radius: 10px;
}

.number-name-container {
    display: flex;
    align-items: center;
}

.heart-time-container {
    display: flex;
    align-items: center;
}

.song-track-number {
    background-color: transparent;
    color: rgb(160, 160, 160);
    border: 0px;
    box-shadow: 0px;
}

.album-buttons-container {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 11px;
}

.add-music-button-container:hover {
    cursor: pointer;
    transform: scale(0.95);
}

.album-play-button {
    background-color: transparent;
    color: rgb(33, 204, 6);
    border: 0px;
    box-shadow: 0px;
    height: 100%;
    font-size: 400%
}

.album-play-button:hover {
    color: rgb(34, 255, 0);
    font-size: 403%;
}

.fa-sharp.fa-solid.fa-circle-play {
    width: 100%;
    height: 100%;
}

.fa-regular.fa-trash-can:hover {
    color: white;
}

.album-update-button {
    color: rgb(160, 160, 160);
    font-size: 150%;
    margin-left: 50%;
}

.album-update-button:hover {
    cursor: pointer;
    color: white;
}


.fa-solid.fa-pen-to-square:hover {
    color: white;
}
