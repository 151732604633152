.signup-form-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 620px;
    background-color: rgb(0, 0, 0);
    padding: 5px;
}

.signup-form-modal-container h1 {
    margin-bottom: 17px;
    margin-top: -9px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 50%;
    position: relative;
    gap: 5px;
    padding-top: 10px;
}

.signup-form::before,
.signup-form::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgb(161, 161, 161);
}

.signup-form::before {
    top: 3px;
    width: 140%;
    left: -45px;
}

.signup-form::after {
    bottom: 65px;
    width: 140%;
    left: -45px;
}

.signup-form label {
    margin: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.signup-form input {
    width: 100%;
    height: 37px;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 4px;
    color: white;
    background-color: rgb(20, 20, 20);
    margin-top: 3px;
}

.signup-form input::placeholder {
    color: rgb(161, 161, 161);
}

.signup-button {
    margin-top: 30px;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(33, 204, 6);
    color: white;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}


.signup-button:hover {
    transform: scale(1.05);
}

.signup-form ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sign-up-link {
    margin-top: 50px;
}

.sign-up-link a {
    text-decoration: underline;
    color: inherit;
    font-weight: bold;
}

.sign-up-link a:hover {
    color: rgb(33, 204, 6);
}

.signup-error {
    /* margin-left: 5px; */
    color:red;
    font-size: smaller;
}

.signup-form li {
    margin-top: 1px;
    color:red;
    font-size:small;
}
