.delete-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 200px;
    background-color: rgb(0, 0, 0);
    padding: 20px;
}

.delete-modal-container h2 {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
}

.delete-modal-container p {
    color: rgb(161, 161, 161);
    font-size: 18px;
    margin-bottom: 25px;
}

.delete-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: red;
    color: white;
    cursor: pointer;
    width: 100px;
    margin-right: 10px;
}

.keep-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(20, 20, 20);
    color: white;
    cursor: pointer;
    width: 100px;
}

.delete-modal-container button:hover {
    transform: scale(1.05);
}
