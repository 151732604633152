.profile-dropdown {
  position: absolute;
  top: 60px;
  right: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  align-items: center;
  padding: 5px 5px;
  background-color: rgb(70, 70, 70);
  border-radius: 10px;
  width: 120px;
  height: fit-content;
  gap: 8px;
  z-index: 99;
}

.profile-dropdown li button {
  align-items: center;
  padding:2px 5px;
  margin-bottom: 3px;
  width: 90px;
  border: none;
  background-color: rgb(69, 69, 69);
  color: rgb(33, 204, 6);
  font-weight: bold;
  font-size: 20px;
}

.profile-dropdown li button:hover {
  /* background-color: rgb(233, 233, 233); */
  color: rgb(233, 233, 233);
}

.hidden {
  display: none;
}

.nav-link {
  text-decoration: none;
  display: flex;
  gap: 20px;
  color: #f5f5f5;
  font-weight:600;
  font-size: large;
}

.nav-link-profile {
  text-decoration: none;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  color: inherit;
}
.nav-link-profile:hover {
  text-decoration: underline;
  font-weight: 600;
}

#NavigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 28px;
  padding-inline-start: 25px;
}

#NavigationContainer li {
  list-style-type: none;
  margin-right: 10px;
}

#nav-right {
  display: flex;
  gap: 20px
}

#profile-bttn {
  width: 29px;
  height: 29px;
  border-radius:50%;
  font-size: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}

#profile-bttn:hover {
  transform: scale(0.90);
}

#profile-bttn:active {
  transform: scale(0.85);
}

.welcome-text {
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
}

/* .welcome-text-symbol {
  font-size: 50px;
  font-weight: bold;
  margin-right: -7px;
} */

#welcome-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
