.rhap_container {
    position: fixed;
    background-color: rgb(0, 0, 0);
    right: 5px;
    margin-right: -5px;
    bottom: 0;
    width: 100%;
}

.rhap_ header {
    display: flex;
    flex-direction: column;
}


.audio-player-art {
    width: 50px;
    height: 50px;
}
