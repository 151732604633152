/* TODO Add site wide styles */
* {
    font-family: 'Lato', sans-serif;
}

html {
    /* background-color: rgb(19, 19, 19); */
    font-family: 'Lato', sans-serif;
    color: #f5f5f5;
    background-image: linear-gradient(#1a1a1a, #000);
    min-height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

button {
    cursor: pointer;
}
