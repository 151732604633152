.profile-tile-buttons {
  gap:2px
}

.update-delte-music-buttons {
    color: rgb(160, 160, 160);
    gap:5px
  }

.update-delte-music-buttons:hover {
    cursor: pointer;
    color: white;
  }
