.login-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 600px;
    background-color: rgb(0, 0, 0);
    padding: 5px;
}


.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 50%;
    position: relative;
}

.login-form::before,
.login-form::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgb(30, 30, 30);
}

.login-form::before {
    top: 92px;
    width: 140%;
    left: -45px
}

.login-form::after {
    bottom: -40px;
    width: 140%;
    left: -45px
}

.login-form label {
    margin: 10px 0;
    width: 100%;
}

.login-form input {
    width: 100%;
    height: 37px;
    /* padding: 5px 15px; */
    border: 1px solid rgb(161, 161, 161);
    border-radius: 4px;
    color: white;
    background-color: rgb(20, 20, 20);
}

.login-form input:active{
    border: 2px solid rgb(161, 161, 161);
}

.login-form input::placeholder {
    color: rgb(161, 161, 161);
}

.login-form button {
    margin-top: 20px;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: rgb(33, 204, 6);
    color: white;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}

.login-form button.login-button:hover {
    transform: scale(1.05)
}

.login-form button.demoUserLink {
    background-color: black;
    border: 1px solid rgb(161, 161, 161);
    margin-bottom: 45px;
    padding: 16px;
    font-weight: bolder;
}

.login-form button.demoUserLink:hover {
    background-color: black;
    border: 1px solid white;
}

.login-form ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sign-up-link {

    margin-top: 50px;
}
.sign-up-link a {
    text-decoration: underline;
    color: inherit;
    font-weight: bold;
}

.sign-up-link a:hover {
    color: rgb(33, 204, 6)
}
