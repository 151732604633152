#footer-container {
  padding-left: 10px;
  display: flex;
  gap: 60px;
  border-top: solid rgba(255, 255, 255, 0.237) 1px;
  padding-top: 15px;
  margin-left: 40px;
  margin-top: 35px;
  margin-bottom: 118px;
}

.footer-col {
  display: flex;
  flex-direction: column;
}

.footer-header {
  margin-bottom: 5px;
}

span,
#for>a {
  color: rgba(255, 255, 255, 0.465);
  text-decoration: none
}

a {
  color: white;
  text-decoration: none;
}

.creator-icons {
  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-left: 2px;
}

.creator-link-icon {
  display: none;
  color: rgba(255, 255, 255, 0.465);
}

.creator-link-icon:hover,
#for>a:hover {
  color: white;
}

.creator-text:hover {
  color: white;
  cursor: default
}

.linkedin-image {
  color: white;
}
