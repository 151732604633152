.playlist-details-container {
    padding-left: 3%;
}

.playlist-header-container {
    display: flex;
    margin-bottom: 2%;
}

.playlist-info-container {
    width: 100%;
    align-self: bottom;
    padding-left: 3%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#playlist-description {
    font-style: italic;
}

.playlist-details-art {
    width: 270px;
    height: 270px;
    object-fit: cover;
}

#playlist-duration {
    color: rgba(255, 255, 255, 0.701)
}

.playlist-name-header {
    font-size: 400%;
    margin-top: 0%;
    margin-bottom: 0%;
}

#playlist-artists {
    margin-bottom: 2.5px;
}

#playlist-duration {
    margin-top: 2.5px;
}

.playlist-songs-container {
    padding-left: 0%;
    margin-top: 1%;
}

.playlist-songs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    border-bottom: .5px solid rgb(74, 74, 74);
}

#playlist-clock-icon {
    /* margin-right: -2.5%; */
    padding-right: 0;
    margin-right: 0.5%;
}

.fa-solid.fa-circle-minus:hover {
    color: rgb(255, 50, 50);
}

.playlist-song-time {
    color: rgb(160, 160, 160);

}
