.home-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.your-library-container {
    width: 25%;
    background-color: rgb(13, 13, 13);
    border-radius: 20px;
}

.your-library-container h2 {
    margin-left: 12px;
}

.your-library-container h3 {
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.discover-music-container {
    width: 75%;
    margin-left: 40px;
}

.album-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr));
    gap: 17px;
}

.album-tile {
    width: 190px;
    height: 240px;
    list-style-type: none;
    text-align: center;
    background-color: rgb(28, 28, 28);
    margin-top: 0px;
    border-radius: 8px;
    transition: background-color 0.4s, color 0.4s;
    position: relative;
}

.album-tile:hover {
    background-color: rgb(40, 40, 40);
    text-decoration: none;
}

.album-tile Link {
    text-decoration: none;
}

.album-tile Link:hover {
    text-decoration: none;
}

.album-tile h3,
.album-tile p {
    margin: 0px;
    font-size: medium;
    padding: 0px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.album-image {
    width: 165px;
    height: 165px;
    object-fit: cover;
    border-radius: 8px;
    margin: 10px
}


.owner-text {
    color: rgba(255, 255, 255, 0.506);
}

#all-albums-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

#all-songs-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

/* #all-playlist-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
} */

.album-grid-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 20px;
}

.link-as-text {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

/* .item-scroll div {
    gap: 50px;
} */

.fa-solid.fa-angles-left {
    margin-right: 10px;
    cursor: pointer;
    color: rgb(160, 160, 160);
}

.fa-solid.fa-angles-left:hover {
    color: white;
    font-weight: 900;
}

.fa-solid.fa-angles-right {
    cursor: pointer;
    color: rgb(160, 160, 160);
}

.fa-solid.fa-angles-right:hover {
    color: white;
    font-weight: 900;
}

.library-container {
    margin-right: 20px;
    margin-left: 10px;
}

.playlist-tile {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    text-decoration: none;
    border-radius: 20px;
    background-color: rgb(28, 28, 28);
    /* margin-right: 10px; */
    transition: background-color 0.4s;
}

.playlist-tile:hover {
    background-color: rgb(40, 40, 40);
}

.playlist-image {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 10px;
    object-fit: cover;
}

.playlist-tile h3 {
    color: white;
}

.create-playlist-link {
    background-color: rgb(33, 204, 6);
    color: white;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-top: 20px;
    height: 50px;
    transition: background-color 0.4s, color 0.4s;
}

.create-playlist-link:hover {
    background-color: rgb(233, 233, 233);
    color: rgb(12, 147, 0);
}

.loginbuttonlibrary {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginbuttonlibrary button {
    background-color: rgb(33, 204, 6);
    border: none;
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-top: 20px;
    height: 50px;
    transition: background-color 0.4s, color 0.4s;
    width: 92%;
}

.loginbuttonlibrary button:hover {
    background-color: rgb(233, 233, 233);
    color: rgb(12, 147, 0);
}

.play-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: transparent;
    padding: 2px;
    transition: 0.1s;
    color: rgb(33, 204, 6);
}

.play-button:hover {
    transform: scale(1.45)
}

.play-button:active {
    transform: scale(1.30)
}

@media (max-width: 1200px) {
    .album-tile {
        width: 185px;
        height: 235px;
    }

    .album-image {
        width: 160px;
        height: 160px
    }
}

@media (max-width: 1150px) {
    .album-tile {
        width: 180px;
        height: 230px;
    }

    .album-image {
        width: 155px;
        height: 155px
    }
}

@media (max-width: 1120px) {
    .album-tile {
        width: 175px;
        height: 225px;
    }

    .album-image {
        width: 150px;
        height: 150px
    }
}

@media (max-width: 1090px) {
    .album-tile {
        width: 170px;
        height: 220px;
    }

    .album-image {
        width: 145px;
        height: 145px;
    }
}

@media (max-width: 1070px) {
    .album-tile {
        width: 165px;
        height: 215px;
    }

    .album-image {
        width: 140px;
        height: 140px;
    }
}

@media (max-width: 1045px) {
    .album-tile {
        width: 160px;
        height: 210px;
    }

    .album-image {
        width: 135px;
        height: 135px;
    }
}

@media (max-width: 1015px) {
    .album-tile {
        width: 155px;
        height: 205px;
    }

    .album-image {
        width: 130px;
        height: 130px;
    }
}

@media (max-width: 990px) {
    .album-tile {
        width: 155px;
        height: 200px;
    }

    .album-image {
        width: 130px;
        height: 130px;
    }
}

@media (max-width: 975px) {
    .album-tile {
        width: 150px;
        height: 195px;
    }

    .album-image {
        width: 125px;
        height: 125px;
    }
}

@media (max-width: 950px) {
    .album-tile {
        width: 145px;
        height: 190px;
    }

    .album-image {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 925px) {
    .album-tile {
        width: 140px;
        height: 185px;
    }

    .album-image {
        width: 115px;
        height: 115px;
    }
}

@media (max-width: 900px) {
    .album-tile {
        width: 135px;
        height: 180px;
    }

    .album-image {
        width: 110px;
        height: 110px;
    }
}

@media (max-width: 875px) {
    .album-tile {
        width: 130px;
        height: 175px;
    }

    .album-image {
        width: 105px;
        height: 105px;
    }
}

@media (max-width: 850px) {
    .album-tile {
        width: 125px;
        height: 170px;
    }

    .album-image {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 825px) {
    .album-tile {
        width: 120px;
        height: 165px;
    }

    .album-image {
        width: 95px;
        height: 95px;
    }
}

@media (max-width: 800px) {
    .album-tile {
        width: 115px;
        height: 160px;
    }

    .album-image {
        width: 90px;
        height: 90px;
    }
}

@media (max-width: 775px) {
    .album-tile {
        width: 110px;
        height: 155px;
    }

    .album-image {
        width: 85px;
        height: 85px;
    }
}

@media (max-width: 750px) {
    .album-tile {
        width: 105px;
        height: 150px;
    }

    .album-image {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 725px) {
    .album-tile {
        width: 100px;
        height: 145px;
    }

    .album-image {
        width: 78px;
        height: 75px;
    }
}

@media (max-width: 700px) {
    .album-tile {
        width: 95px;
        height: 140px;
    }

    .album-image {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 675px) {
    .album-tile {
        width: 90px;
        height: 135px;
    }

    .album-image {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 650px) {
    .album-tile {
        width: 85px;
        height: 135px;
    }

    .album-image {
        width: 65px;
        height: 65px;
    }
}

@media (max-width: 625px) {
    .album-tile {
        width: 80px;
        height: 130px;
    }

    .album-image {
        width: 60px;
        height: 60px;
    }
}
